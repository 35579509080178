import { apolloClient } from '@/plugins/apollo-client';
import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

export const getResponseFileUpload = (variables) =>
  apolloClient
    .query({ query: RESPONSE_FILE_UPLOAD_QUERY, variables })
    .then((result) => result.data.requestResponseFileUpload);

const RESPONSE_FILE_UPLOAD_QUERY = gql`
  query responseFileUpload($requestId: ID!, $filename: String!) {
    requestResponseFileUpload(filename: $filename, requestId: $requestId) {
      filePath
      url
      fields
    }
  }
`;

const onDoneSubscribedCallbacks = [];

export const useCreateResponse = () => {
  const { mutate, onDone } = useMutation(REQUEST_RESPONSE_CREATE);

  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });
  const onDoneCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    mutate,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
  };
};

const REQUEST_RESPONSE_CREATE = gql`
  mutation requestResponseCreate($requestId: ID!, $createParams: ResponseCreateInput!) {
    requestResponseCreate(requestId: $requestId, createParams: $createParams) {
      id
    }
  }
`;
