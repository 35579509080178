<template>
  <div class="details-container p-4">
    <div class="d-flex gap-3 align-items-center">
      <div class="fields d-flex flex-column gap-2 text-typography-secondary">
        <p>{{ translate('meantFor') }}</p>
        <p>{{ translate('requestType') }}</p>
        <p>{{ translate('details') }}</p>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="requester-field gap-2">
          <div class="requester-icon rounded-pill">
            <img v-if="requester?.logoUrl" :src="`${requester.logoUrl}&token=${token}`" width="24" height="24" />
            <BusinessIcon v-else :size="16" />
          </div>
          <p class="fw-bold">{{ requester.name }}</p>
          &#183;
          <p>{{ $t('commons.companyNumber') }} {{ requester.number }}</p>
          &#183;
          <p>{{ $t('terms.supplierTerms.generalTerms.customerNumber') }} {{ requester.number }}</p>
          <p></p>
        </div>

        <p>{{ requestTypeDescription }}</p>
        <p>{{ translate('reconciliation', { date: reconciliationDate }) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, computed } from 'vue';

import { useTenancy } from '@/modules/auth';
import { BusinessIcon } from '@/assets/icons';
import { useTerms } from '@/modules/term';

import { REQUEST_TYPES } from '../../../../constants';

const requestTypeMapping = {
  [REQUEST_TYPES.BALANCE_ALIGNMENT]: 'uploadReconciliationDocs',
  [REQUEST_TYPES.BALANCE_ALIGNMENT_RECONCILIATION_STATEMENT]: 'uploadReconciliationDocsAndConsolidated',
  [REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS]: 'missingInvoices',
};

export default {
  components: { BusinessIcon },
  props: {
    request: { type: Object, required: true },
    requester: { type: Object, required: true },
  },
  setup(props) {
    const { $i18n } = getCurrentInstance().proxy;
    const translate = (key, params) => $i18n.t(`requestsSupplier.responseModal.${key}`, params);
    const { token } = useTenancy();
    const reconciliationDate =
      props.request.template === REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS
        ? props.request.templateData[props.request.template].documents[0].docDate
        : props.request.templateData[props.request.template].reconciliationPeriodEnd;

    const { terms: generalTerms } = useTerms(
      computed(() => ({
        type: 'general',
        businessId: props.request.sourceBusinessId,
        supplierId: props.request.targetBusinessId,
      }))
    );

    return {
      translate,
      token,
      requestTypeDescription: translate(`requestTypes.${requestTypeMapping[props.request.template]}`),
      reconciliationDate: new Date(reconciliationDate).toLocaleDateString($i18n.locale, {
        month: 'long',
        year: 'numeric',
      }),
      customerNumber: computed(() => generalTerms.value.customerNumber),
    };
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  border: 1px solid #e3e4e6;
  border-radius: 0.25rem;

  p {
    line-height: 1.5rem;
  }
}

.fields {
  width: 7.25rem;
}

.requester-field {
  display: flex;
  align-items: center;

  .requester-icon {
    background-color: #fbfbfb;
    border: 1px solid #d9dcde;
  }
}
</style>
