import jsPDF from 'jspdf';

export const convertImageToPdf = async (file) => {
  const extension = file.name.split('.').pop();
  const dataURL = await fileToDataURL(file);

  return dataURLToPDFFile(file, dataURL, extension);
};

const fileToDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
  });
};

const dataURLToPDFFile = (file, dataURL, extension) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const pdfFile = createPDF(image, extension, file.name);
      resolve(pdfFile);
    };
    image.onerror = () => reject(new Error('Failed to load image'));
    image.src = dataURL;
  });
};

const createPDF = (image, extension, fileName) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  // Rotate the canvas if width > height
  if (image.width > image.height) {
    canvas.width = image.height;
    canvas.height = image.width;
    ctx.rotate(Math.PI / 2);
    ctx.translate(0, -canvas.width);
  }

  ctx.drawImage(image, 0, 0);

  const compressedDataURL = canvas.toDataURL(`image/${extension.toLowerCase()}`, 0.3);
  const pdf = new jsPDF('portrait', 'mm', 'a4', true);

  const { imgWidth, imgHeight } = calculateImageDimensionsForPDF(canvas);

  pdf.addImage({
    imageData: compressedDataURL,
    format: extension,
    x: 0,
    y: 0,
    width: imgWidth,
    height: imgHeight,
    compression: 'FAST',
    rotation: 0,
  });

  const pdfBlob = pdf.output('blob');
  return new File([pdfBlob], `${fileName.split('.').slice(0, -1).join('.')}.pdf`, {
    type: 'application/pdf',
  });
};

const calculateImageDimensionsForPDF = (canvas) => {
  const pdfWidth = 210;
  const pdfHeight = 297;
  const aspectRatio = canvas.width / canvas.height;
  let imgWidth = pdfWidth;
  let imgHeight = pdfWidth / aspectRatio;

  if (imgHeight > pdfHeight) {
    imgHeight = pdfHeight;
    imgWidth = pdfHeight * aspectRatio;
  }

  return { imgWidth, imgHeight };
};
