import { gql } from '@apollo/client/core';

export const USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      memberships {
        role
      }
    }
  }
`;
